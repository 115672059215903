import React from "react"
import styled from "styled-components"
import { Container } from "./utils"
import { Display } from "./typography"
import { Box } from "theme-ui"
import Nav from "./nav"
import MobileNav from "./mobileNav"
import DesignTokens from "./designTokens"
import background from "../images/hero-image.jpg"

const Header = ({ title, locale, theme }) => (
  <Wrapper theme={theme} title={title}>
    <Box sx={{ display: ["none", "none", "block", "block"] }}>
      <Nav locale={locale} theme={theme} />
    </Box>
    <Box sx={{ display: ["block", "block", "none", "none"] }}>
      <MobileNav locale={locale} />
    </Box>
    {title && (
      <CustomContainer>
        <CustomDisplay theme={theme}>{title}</CustomDisplay>
      </CustomContainer>
    )}
  </Wrapper>
)
const Wrapper = styled.div`
  background-color: ${props =>
    props.theme === "light"
      ? DesignTokens.colors.white
      : DesignTokens.colors.secondary[500]};
  background-image: ${props =>
    props.theme === "light" ? null : `url(${background})`};
  background-size: cover;
  background-position: top center;
  margin-bottom: 4rem;
  ${props => props.title && `margin-bottom: 0;`}
  color: ${props =>
    props.theme === "light"
      ? DesignTokens.colors.dark
      : DesignTokens.colors.white};
  @media screen and (max-width: 767px) {
      background-size: 400%;
      background-position: center;
}
`

const CustomDisplay = styled(Display)`
  text-align: right;
  color: ${props =>
    props.theme === "light"
      ? DesignTokens.colors.primary[500]
      : DesignTokens.colors.white};
`

const CustomContainer = styled(Container)`
  padding: 6rem 0 4rem;
`

export default Header
