import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import RichContentBlockText from "../components/richContentBlockText"
import { Caption, Heading } from "../components/typography"
import { Container } from "../components/utils"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import { getNewsPostPath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"

const NewsPage = ({ data: { page, site }, pageContext }) => {
  const locale = pageContext.locale
  const i18nPaths = page._allSlugLocales.map(l => {
    return {
      locale: l.locale,
      value: getNewsPostPath(page, l.locale),
    }
  })

  return (
    <Layout locale={locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags}>
        <html lang={locale} />
      </HelmetDatoCms>
      <Header locale={locale} theme={"light"} />
      <CustomContainer>
        {page.heroImage && (
          <img src={page.heroImage.url} alt={page.heroImage.alt} />
        )}
        <Article>
          <Caption color={DesignTokens.colors.primary[500]}>
            {new Intl.DateTimeFormat(locale, {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(new Date(page.meta.publishedAt))}
          </Caption>
          <Heading>{page.title}</Heading>
          <RichContentBlockText html={page.text} />
        </Article>
      </CustomContainer>
    </Layout>
  )
}

const CustomContainer = styled(Container)`
  padding-bottom: 10rem;
  img {
    border-radius: ${DesignTokens.borderRadius};
    width: 100%;
  }
  & > * {
    margin-bottom: ${DesignTokens.spacing[10]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Article = styled.article`
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  & > * {
    margin-bottom: ${DesignTokens.spacing[6]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default NewsPage

export const query = graphql`
  query NewsQuery($slug: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsNews(slug: { eq: $slug }, locale: { eq: $locale }) {
      title
      text
      _allSlugLocales {
        value
        locale
      }
      meta {
        publishedAt
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroImage {
        url
        alt
      }
    }
  }
`
